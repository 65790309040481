import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Buttons from '../Buttons'
import ProgressBar from '../ProgressBar'

export default function DurationForm({ prevStep, nextStep, updateOptions, updateAppOptions, values, steps }) {
    const { i18n } = useTranslation();
    const language = i18n.language;
    const [value, setValue] = useState(values ? values : 1)
    const [error, setError] = useState(true)
    const { t } = useTranslation()
    const durations_en = ['4 Weeks', '8 Weeks', '3 Months', '4 Months', '5 Months', '6 Months', '9 Months', '12 Months', '15 Months', '1 Year 6 Months', '1 Year 9 Months', '2 Years']
    const durations_de = ['4 Wochen', '8 Wochen', '3 Monate', '4 Monate', '5 Monate', '6 Monate', '9 Monate', '12 Monate', '15 Monate', '1 Jahr 6 Monate', '1 Jahr 9 Monate', '2 Jahre']
    
    const setTime = language === "de" ? durations_de[value - 1] : durations_en[value - 1];

    useEffect(() => {
        updateOptions('duration', setTime)
    }, [setTime])

    return (
        <Fragment>
            <div className="heading-container">
                <h2 className="header">{t('Website.WebsiteSpeed.Question')}</h2>
            </div>
            <label className="validation-error">*<span className="validation-text">{t('Validation.Value')}</span></label>                    
            <div className="form-submit">
            <h4 className="duration-header">{setTime}</h4>
            <div className="slider-parent">
                <input type="range" value={value} min="1" className="duration-slider" max="12" value={value} onChange={({ target: { value: radius } }) => {
                    setValue(radius)
                    setError(false)
                }}/>
            </div>
                {
                    language === "de" ?
                    <div className="duration-range">
                            <h4>{durations_de[0]}</h4>
                            <h4>{durations_de[durations_de.length - 1]}</h4>
                    </div> :
                    <div className="duration-range">
                            <h4>{durations_en[0]}</h4>
                            <h4>{durations_en[durations_en.length - 1]}</h4>
                    </div>
                }
                <Buttons prevStep={prevStep} nextStep={nextStep} error={error} errorMessage={t('Validation.Value')}/>
                <ProgressBar value={steps[0]} max={steps[1]} />
            </div>
            
        </Fragment>
    )
}