import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Buttons from './Buttons';

export default function UserDetailsForm({ isApp, user, changeUserData, prevStep, nextStep, errors, validateForm, handleSubmit}) {
    const { t } = useTranslation()
    const all = errors.name && errors.email && errors.phone
    const name = (errors.name && !errors.email && !errors.phone) || (errors.name && errors.email && !errors.phone) || (errors.name && !errors.email && errors.phone)
    const email = (errors.email && !errors.phone && !errors.name) || (errors.email && errors.phone && !errors.name)
    const phone = (!errors.email && errors.phone && !errors.name) || (!errors.email && errors.phone && !errors.name)

    return (
        <Fragment>
            <div className="heading-container">
            <h2 className="header">{t('BasicInfo')}</h2>
            </div>
            <div className="form-container">
                <div className="inner-container mt">
                    <form>             
                    <input type="text" 
                        className={classNames("inpt1", { "highlight": errors.name})}
                        value={user.name}
                        onChange={changeUserData('name')} 
                        id="name"
                        placeholder="Name"/>                    
                    <input type="email" 
                        className={classNames("inpt1", { "highlight": errors.email})}
                        value={user.email} 
                        onChange={changeUserData('email')} 
                        placeholder={t('Email')}/>
                    <input type="number" 
                        className={classNames("inpt1", { "highlight": errors.phone})}
                        value={user.phone} 
                        onChange={changeUserData('phone')} 
                        placeholder={t('Phone')}/>
                    </form>
                    <Buttons  prevStep={prevStep} isApp={isApp} nextStep={nextStep} validateForm={validateForm} submit={true} submitForm={handleSubmit} error={(!user. name || !user.email || !user.phone) ? true : false} errorMessage={all ? t('AllError') : name ? t('NameError') : email ? t('EmailError') : phone ? t('PhoneError') : t('AllError') }/>
                </div>
            </div>
        </Fragment>
    )
}