import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Buttons({prevStep, nextStep, step, submit = false, isApp = false, submitForm, validateForm = null, error = false, errorMessage}) {
    const { t } = useTranslation()
    return (
            <div className="buttons-row">
                    <a onClick={e => {
                        e.preventDefault()
                        prevStep()
                    }} style={{cursor: 'pointer'}}>
                        <AiOutlineArrowLeft size={14} style={{marginRight: 6}}/>
                        {t('Back')} 
                    </a>
                    {submit
                    ? <a onClick={e => {
                        e.preventDefault()
                        if(!error) {
                            submitForm()
                            nextStep()
                        } else {
                            if(errorMessage) {
                                console.warn(errorMessage)
                                toast.error(errorMessage, {
                                    position: "bottom-center",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });
                            }
                        }
                    }} className="btn1 sendmrgn2">{t('Send')}</a>
                    : <a onClick={e => {
                        e.preventDefault()
                        if(!error) {
                            validateForm ? validateForm(isApp) : nextStep()
                        } else {
                            if(errorMessage) {
                                console.warn(errorMessage)
                                toast.error(errorMessage, {
                                    position: "bottom-center",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });
                            }
                        }
                    }} className="btn1 sendmrgn1">{t('Continue')}</a>
                    }
                    {/*<a className="button-width"></a>*/}
            </div>
    )
}