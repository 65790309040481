import React from 'react'
import {useTranslation} from 'react-i18next'

export default function SuccessPage() {
    const { t } = useTranslation()

    function handleClick(e) {
        e.preventDefault(); // prevents auto link by render
        window.location.href = './index.html'
    }

    return (
        <div id="succesful" style={{textAlign: 'center', margin:'0 15px'}}>
            <h3>{t('Done')}</h3>
            <progress value={2} max={2} style={{height: '10px', width: '300px'}}></progress>
            <div style={{marginTop: 50}}>
                <h2>{t('Success.line1')}</h2>
                <h2>{t('Success.line2')}</h2>
            </div>
            <div style={{paddingTop: '10%'}}>
                {/*<a onClick={window.location.href = './index.html'} className="btn1" style={{textDecoration: "none", paddingTop: '10px'}}>{t('Close')}</a>*/}
                <a onClick={handleClick} className="btn1" style={{textDecoration: "none"}}>{t('Close')}</a>
            </div>
        </div>
    )
}