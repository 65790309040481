import React, { Fragment, useEffect, useState } from 'react'
import { BiCheck } from 'react-icons/bi'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Buttons from '../Buttons';
import ProgressBar from '../ProgressBar'

export default function ScreenAlignmentForm({ prevStep, nextStep, updateAppOptions, values }) {
    const [selectedOptions, setSelectedOptions] = useState(values)
    const { t } = useTranslation()

    useEffect(() => {
        updateAppOptions('alignment', selectedOptions)
    }, [selectedOptions])

    const options = {
        Choice1: "Landscape",
        Choice2: "Potrait",
        Choice3: "Both"
    }

    return (
        <Fragment>
            <div className="heading-container">
                <h2 className="header">{t('App.Alignment.Question')}</h2>
            </div>
            <label className="validation-error">*<span className="validation-text">{t('Validation.One')}</span></label>
            <div className="form-container">
                <Fragment>
                    <div className="inner-container">
                        {Object.entries(options).map(([key, value], idx) => (
                            <div className={classNames("select-box", "screen", {"selected": selectedOptions === value})} key={idx} onClick={() => setSelectedOptions(value)}>
                                <h4>{t(`App.Alignment.${key}`)}</h4>
                                <div className="tooltip-ex">
                                    <span className="more-info" style={selectedOptions === value ? { display: 'none'} : {}}>&#63;</span>
                                    <span class="tooltip-ex-text">
                                        {t(`Tooltip.App.${key}`)}
                                    </span>
                                </div>
                                <BiCheck size={20} style={selectedOptions !== value && { display: 'none' }} />
                            </div>
                        ))}
                    </div>
                    <Buttons prevStep={prevStep} nextStep={nextStep} error={selectedOptions === '' ? true : false} errorMessage={t('Validation.One')}/>
                    <ProgressBar value="6" max="12"/>
                </Fragment>
            
            </div>
            
        </Fragment>
    )
}