import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Buttons from "../Buttons";
import ProgressBar from "../ProgressBar";
function numberWithCommas(x) {
  return x.toString().replace(/.(?=(?:.{3})+$)/g, "$&.");
}
export default function BudgetForm({
  prevStep,
  nextStep,
  updateOptions,
  values,
  steps
}) {
  const [value, setValue] = useState(19000);
  const [upper, setUpper] = useState(250000);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const budget = [value, upper];
    updateOptions("budget", budget);
  }, [value, upper]);

  return (
    <Fragment>
      <div className="heading-container">
        <h2 className="header" style={{whiteSpace: 'nowrap'}}>{t("App.Budget.Question")}</h2>
      </div>
      <label className="validation-error">
        *<span className="validation-text">{t("Validation.Value")}</span>
      </label>
      <div style={{ textAlign: "center", marginBlock: 20 }}>
        <h4 style={{ fontWeight: 400, marginBottom: 20 }}>
          {t("App.Budget.Answer")}
        </h4>
        <h4 style={{ fontWeight: 400, marginBottom: 20, fontSize: '22px' }}>
          {numberWithCommas(value)}€ - {numberWithCommas(upper)}€
        </h4>
      </div>
      <div className="form-submit">
        <div className="slider-parent">
          <input
            type="range"
            value={value}
            min="19000"
            max="250000"
            step="1000"
            className="duration-slider"
            onChange={({ target: { value: radius } }) => {
              setValue(radius);
              setError(false);
              if (radius > parseInt(upper) - 1000) {
                setValue(parseInt(upper) - 1000);
              }
            }}
          />
          <input
            type="range"
            value={upper}
            min="19000"
            max="250000"
            step="1000"
            className="duration-slider"
            onChange={({ target: { value: radius } }) => {
              setUpper(radius);
              setError(false);
              if (radius < parseInt(value) + 1000) {
                setUpper(parseInt(value) + 1000);
              }
            }}
          />
        </div>
        <div className="slider-range">
          <h4>19.000€</h4>
          <h4>250.000€</h4>
        </div>
        <Buttons
          prevStep={prevStep}
          nextStep={nextStep}
          error={error ? true : false}
        />
        <ProgressBar value={steps[0]} max={steps[1]} />
      </div>
    </Fragment>
  );
}
